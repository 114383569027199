import Loading from "components/Loading";
import Permission from "components/Permission";
import PermissionDeniedPage from "components/PermissionDenied";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_UNIT_GROUP } from "shared/constants";

const ConfigModuleUpdateBuilding = React.lazy(
  () =>
    import(
      "containers/ProjectManagement/ConfigBuilding/Configs/ConfigTemplate/UpdateTemplateView"
    )
);

function ConfigModuleUpdateBuildingRoute() {
  return (
    <Route
      path="building/:idBuilding/config/template/:templateName"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<ConfigModuleUpdateBuilding />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_UNIT_GROUP]}
          />
        </Suspense>
      }
    />
  );
}

export default ConfigModuleUpdateBuildingRoute;
