import { language } from "shared/language";
import { convertArrToObj } from "shared/utils";

export const CATEGORY_HOT_DEAL_DEFAULT_FILTER = {
  name: undefined,
  status: undefined,
};

export const STATUS_CATEGORY_SHOW = "true";
export const STATUS_CATEGORY_HIDE = "false";

export const STATUS_CATEGORY = [
  {
    value: STATUS_CATEGORY_SHOW,
    name: language.show,
    color: "green",
  },
  {
    value: STATUS_CATEGORY_HIDE,
    name: language.hide,
    color: "default",
  },
];
export const STATUS_CATEGORY_OBJ = convertArrToObj(STATUS_CATEGORY, "id");
