import { Route } from "react-router-dom";
import BuildingRoute from "./buildingRoute";
import VersionRoute from "./versionRoute";
import IconRoute from "./iconRoute";
import NotificationRoute from "./notificationRoute";
import WhiteLabelRoute from "./whiteLabelRoute";
import CityRoute from "./cityRoute";
import DistrictRoute from "./district";
import WardRoute from "./ward";
import BannerRoute from "./bannerRoute";
import ServicePartnerRoute from "./servicePartner";
import AdsPartnerRoute from "./adsPartnerRoute";
import PartnerOrderRoute from "./partnerOrderRoute";
import BillLockRoute from "./billLockRoute";
import ConfigModuleForBuildingRoute from "./configModuleForBuilding";
import ModuleConfigRoute from "./moduleConfigRoute";
import RemoteConfigRoute from "./remoteConfigRoute";
import MerchantRoute from "./merchantRoute";
import MerchantUtilityRoute from "./merchantUtilityRoute";
import MerchantOrderRoute from "./merchantOrderRoute";
import StorageRoute from "./storageRoute";
import ConfigModuleUpdateBuildingRoute from "./configModuleUpdateBuilding";
import CategoryHotDealRoute from "./categoryHotDeal";
import HotDealRoute from "./hotDeal";

function ManagementRoutes() {
  return (
    <Route path="management">
      {BuildingRoute()}
      {IconRoute()}
      {VersionRoute()}
      {WhiteLabelRoute()}
      {NotificationRoute()}
      {CityRoute()}
      {DistrictRoute()}
      {WardRoute()}
      {BannerRoute()}
      {RemoteConfigRoute()}
      {ServicePartnerRoute()}
      {CategoryHotDealRoute()}
      {HotDealRoute()}
      {AdsPartnerRoute()}
      {PartnerOrderRoute()}
      {MerchantOrderRoute()}
      {BillLockRoute()}
      {PartnerOrderRoute()}
      {ConfigModuleForBuildingRoute()}
      {ModuleConfigRoute()}
      {MerchantRoute()}
      {MerchantUtilityRoute()}
      {StorageRoute()}
      {ConfigModuleUpdateBuildingRoute()}
    </Route>
  );
}

export default ManagementRoutes;
